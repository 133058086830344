import Forms from "./forms"

function disableSwitchableButtons() {
  const $switchableButtons = $('.btn[data-switchable="true"]', $(".bottombar"))

  $switchableButtons.prop("disabled", true)
}

function enableSwitchableButtons() {
  const $switchableButtons = $('.btn[data-switchable="true"]', $(".bottombar"))

  $switchableButtons.prop("disabled", false)
}

const BottomBar = {
  show(): void {
    $(".bottombar").slideDown()
  },

  toggleSaveButton($targetForm: JQuery<HTMLFormElement>): void {
    if (
      Forms.requirements.emptyRequiredFieldsArePresent($targetForm) ||
      Forms.errors.arePresent($targetForm)
    ) {
      disableSwitchableButtons()
    } else {
      enableSwitchableButtons()
    }
  },
}

export default BottomBar
