import BottomBar from "./bottombar"

/**
 * Custom events that a form can trigger
 *
 * oskar:form:allRequiredFieldsAreFilled
 */

const Forms = {
  errors: {
    arePresent($targetForm: JQuery<HTMLFormElement>): boolean {
      const $errors = $targetForm.find(".error")
      const $visibleErrorMessages = $errors.find(".message:visible")

      return $visibleErrorMessages.length ? true : false
    },
  },

  changesOccurred($targetForm: JQuery<HTMLFormElement>): void {
    const formPage = $targetForm.data("page"),
      formIsInSaveMode = $targetForm.attr("data-save-mode")

    if (formPage === "edit" || formPage === "update") {
      BottomBar.show()
    }

    if (formIsInSaveMode) {
      $targetForm.attr("data-changed", "true")
    }
  },

  listenForChanges($targetForm: JQuery<HTMLFormElement>): void {
    const $fileFields = $targetForm
      .find('input[type="file"]')
      .not("[readonly], [disabled], .not-listen")
    const $MDCRadioButtonContainers = $targetForm
      .find(".mdc-radio")
      .not("[readonly], [disabled], .not-listen")
    const $MDCRadioButtons = $MDCRadioButtonContainers
      .find('input[type="radio"]')
      .not("[readonly], [disabled], .not-listen")
    const $selects = $targetForm
      .find("select:enabled")
      .not("[readonly], [disabled], .not-listen")
    const $textInputs = $targetForm
      .find(
        'input[type="text"], input[type="number"], input[type="email"], input[type="password"], input[type="url"], input[type="tel"]'
      )
      .not("[readonly], [disabled], .not-listen")

    $fileFields.off("change.oskar").on("change.oskar", () => {
      Forms.changesOccurred($targetForm)
    })

    $MDCRadioButtons.off("change.oskar").on("change.oskar", () => {
      Forms.changesOccurred($targetForm)
    })

    $selects.off("change.oskar").on("change.oskar", () => {
      Forms.changesOccurred($targetForm)
    })

    $textInputs.off("change.oskar").on("change.oskar keyup.oskar", () => {
      Forms.changesOccurred($targetForm)
    })
  },

  notifications: {
    hide($targetForm: JQuery<HTMLFormElement>): void {
      const $notifications = $targetForm.find(".notifications")
      const $notificationsContent = $notifications.find("div")

      $notificationsContent.slideUp(() => {
        $notifications.empty()
      })
    },
    showError($targetForm: JQuery<HTMLFormElement>): void {
      const $notifications = $targetForm.find(".notifications")

      $notifications.html(
        '<div class="error">Bitte beheben Sie die angeführten Probleme.</div>'
      )
    },
  },

  requirements: {
    emptyRequiredFieldsArePresent(
      $targetForm: JQuery<HTMLFormElement>
    ): boolean {
      const $visibleInputContainerRequired = $targetForm.find(
        ".input.required:visible"
      )
      const $inputsRequired = $visibleInputContainerRequired.find(
        'input[type="text"], input[type="number"], input[type="email"], input[type="password"], input[type="url"], input[type="tel"]'
      )
      const $selectsRequired = $visibleInputContainerRequired.find("select")
      const $bottomBar = $(".bottombar")
      const labelsRequired: string[] = []
      const $saveButton = $bottomBar.find('.btn.save[data-switchable="true"]')
      const $saveButtonContainer = $saveButton.parents(".btn-container")
      const requiredCount = $inputsRequired.length + $selectsRequired.length
      let filledCount = 0
      let labelsRequiredCount = 0
      let tooltipMessage = ""

      if ($saveButtonContainer.length) {
        $($saveButtonContainer).tooltip({ html: true }).tooltip("disable")
      }

      ;[$inputsRequired, $selectsRequired].forEach((inputs) => {
        for (const input of inputs.get()) {
          const $input = $(input)
          let $label = null
          let label = null
          let indexOfLabelAbbreviation = null
          let indexOfLabelSpan = null
          let indexOfPlaceholderPostfix = null
          let placeholder = null
          let text = null

          if (($input.val() as string).trim()) {
            if (filledCount < requiredCount) {
              filledCount++
            }
          } else {
            if (filledCount > 0) {
              filledCount--
            }

            $label = $input.siblings("label")
            label = $label.html()
            placeholder = $input.attr("placeholder")

            if (label && label.length) {
              indexOfLabelAbbreviation = label.indexOf("<abbr")
              indexOfLabelSpan = label.indexOf("<span")

              if (indexOfLabelAbbreviation >= 0) {
                label = label.substr(0, indexOfLabelAbbreviation)
              }

              if (indexOfLabelSpan >= 0) {
                label = label.substr(0, indexOfLabelSpan)
              }

              text = label
            } else {
              if (placeholder?.includes("eingeben …")) {
                indexOfPlaceholderPostfix = placeholder.indexOf("eingeben …")
                placeholder = placeholder.substr(0, indexOfPlaceholderPostfix)
              } else if (placeholder?.includes("…")) {
                indexOfPlaceholderPostfix = placeholder.indexOf("…")
                placeholder = placeholder.substr(0, indexOfPlaceholderPostfix)
              }

              text = placeholder
            }

            labelsRequired.push((text ?? "").trim())
          }
        }
      })

      labelsRequiredCount = labelsRequired.length

      tooltipMessage = "Bitte zuerst "

      for (let i = 0; i < labelsRequiredCount; i++) {
        if (i > 0) {
          if (i === labelsRequiredCount - 1) {
            tooltipMessage += " und "
          } else {
            tooltipMessage += ", "
          }
        }

        tooltipMessage +=
          '<span class="highlight">' + labelsRequired[i] + "</span>"
      }

      tooltipMessage += " ausfüllen."

      if (requiredCount === filledCount) {
        if ($saveButtonContainer.length) {
          $saveButtonContainer.removeAttr("title")
        }

        $targetForm.trigger("oskar:form:allRequiredFieldsAreFilled")

        return false
      } else {
        if ($saveButtonContainer.length) {
          $saveButtonContainer.attr("data-original-title", tooltipMessage)

          $($saveButtonContainer).tooltip("fixTitle").tooltip("enable")
        }

        return true
      }
    },

    setRequiredField($targetField: JQuery): void {
      const $parentInputContainer = $targetField.parents(".input")
      const $parentForm = $targetField.parents("form")
      const $targetFieldLabel = $parentInputContainer.find("label")
      const $targetFieldLabelContent = $targetFieldLabel.html()

      $parentInputContainer.addClass("required")

      $targetFieldLabel.html(
        $targetFieldLabelContent + ' <abbr title="Pflichtfeld">*</abbr>'
      )

      $targetField
        .addClass("required")
        .off("change.oskarRequiredCheck keyup.oskarRequiredCheck")
        .on("change.oskarRequiredCheck keyup.oskarRequiredCheck", () => {
          BottomBar.toggleSaveButton($parentForm)
        })
    },

    unsetRequiredField($targetField: JQuery): void {
      const $parentInputContainer = $targetField.parents(".input")
      const $targetFieldLabel = $parentInputContainer.find("label")
      const $targetFieldLabelContent = $targetFieldLabel.html()

      $parentInputContainer.removeClass("required")
      $targetFieldLabel.html(
        $targetFieldLabelContent.substr(
          0,
          $targetFieldLabelContent.indexOf("<abbr")
        )
      )

      $targetField
        .removeClass("required")
        .off("change.oskarRequiredCheck keyup.oskarRequiredCheck")
    },

    init($targetForms: JQuery<HTMLFormElement>): void {
      $targetForms.each(function () {
        const $targetForm = $<HTMLFormElement>(this)
        const $requiredInputContainers = $targetForm.find(".input.required")
        const $requiredInputFields = $requiredInputContainers.find(
          'input[type="text"], input[type="number"], input[type="email"], input[type="password"], input[type="url"], input[type="tel"]'
        )
        const $requiredSelectFields = $requiredInputContainers.find("select")

        $(this).attr("data-changed", "false")

        BottomBar.toggleSaveButton($targetForm)

        $requiredInputFields
          .off("change.oskarRequiredCheck keyup.oskarRequiredCheck")
          .on("change.oskarRequiredCheck keyup.oskarRequiredCheck", () => {
            BottomBar.toggleSaveButton($targetForm)
          })

        $requiredSelectFields
          .off("change.oskarRequiredCheck")
          .on("change.oskarRequiredCheck", () => {
            BottomBar.toggleSaveButton($targetForm)
          })
      })
    },
  },

  validation: {
    setInvalid($targetInputContainer: JQuery, message: string | null): void {
      const $error = $targetInputContainer.find(".error")
      const $input = $targetInputContainer.find("input")
      const $select = $targetInputContainer.find("select")

      $targetInputContainer.addClass("invalid")

      if (message) {
        $error.html('<div class="message">' + message + "</div>")
      }

      if ($input.length) {
        $input.on("change.oskarValidation keydown.oskarValidation", () => {
          $targetInputContainer.removeClass("invalid")
          Forms.validation.setValid($targetInputContainer)
        })
      }

      if ($select.length) {
        $select.on("change.oskarValidation", () => {
          $targetInputContainer.removeClass("invalid")
          Forms.validation.setValid($targetInputContainer)
        })
      }
    },

    setValid($targetInputContainer: JQuery): void {
      const $error = $targetInputContainer.find(".error")
      const $form = $($targetInputContainer).parents("form")
      const $input = $targetInputContainer.find("input")
      const $select = $targetInputContainer.find("select")
      let $inputContainerInvalid = null

      $targetInputContainer.removeClass("invalid")

      $error.hide()

      $inputContainerInvalid = $(".input.invalid", $form)

      if ($inputContainerInvalid.length <= 0) {
        Forms.notifications.hide($form)
      }

      if ($input.length) {
        $input.off("change.oskarValidation keyup.oskarValidation")
      }

      if ($select.length) {
        $select.off("change.oskarValidation")
      }

      BottomBar.toggleSaveButton($form)
    },
  },

  init(): void {
    // Forms that have this data attribute are being excluded from the
    // otherwise global form validation, bottom bar triggering etc.
    const $forms = $<HTMLFormElement>("form").not("[data-no-global-validation]")

    $forms.each(function () {
      const $formElement = $<HTMLFormElement>(this)
      const $inputContainers = $formElement.find(".input")
      const $textInputs = $inputContainers.find(
        'input[type="text"], input[type="number"], input[type="email"], input[type="password"], input[type="url"], input[type="tel"]'
      )

      $textInputs
        .not('input[data-enterkey="true"]')
        .on("keypress.oskar", (event) => {
          const ENTER = 13

          if (event.keyCode === ENTER) {
            event.preventDefault()
            event.stopPropagation()
          }
        })

      $inputContainers.each(function () {
        if ($(this).hasClass("invalid")) {
          Forms.validation.setInvalid($(this), null)
        }
      })

      $inputContainers.each(function () {
        const $input = $("input", this)

        if ($input.length) {
          if ($input.attr("autofocus")) {
            $(this).addClass("focused")
          }
        }
      })

      $formElement.on("submit.oskar", () => {
        const $bottomBar = $(".bottombar"),
          $btnSave = $(".btn.save", $bottomBar)

        $btnSave.prop("disabled", true)
      })

      Forms.requirements.init($formElement)
      Forms.listenForChanges($formElement)
    })
  },
}

export default Forms
