import Cookies from "./cookies"
import relativeTime from "./relative_time"
import isEmpty from "lodash-es/isEmpty"
import upperFirst from "lodash-es/upperFirst"
import List from "list.js"

export enum EventType {
  INITIAL = "initial",
  FILTER_COMPLETE = "filterComplete",
  SEARCH_COMPLETE = "searchComplete",
  SORT_COMPLETE = "sortComplete",
}

export enum FilterType {
  ARCHIVED = "archived",
}

export enum FilterValue {
  ACTIVE = "active",
  ALL = "all",
  ARCHIVED = "archived",
}

export enum ObjectType {
  PROJECTS = "Projekte",
  TASKS = "Aufgaben",
  USERS = "Personen",
}

export const SORTING_ALPHABET =
  "°^!\"§$%&/{([)]=}?\\`*+'#<>-_" +
  "AaÄäBbCcDdEeFfGgHhIiJjKkLlMmNnOoÖöPpQqRrSsßTtUuÜüVvWwXxYyZz"

const Lists = {
  checkIfListIsEmpty(
    $listContainer: JQuery,
    listObject: List,
    objectType: ObjectType,
    eventType: EventType
  ): void {
    const $emptyListNotifier = $listContainer.find(".empty-list-notifier")
    const $searchField = $listContainer.find(".search input")

    if (isEmpty(listObject.visibleItems)) {
      switch (eventType) {
        case EventType.INITIAL:
          $emptyListNotifier.text(
            `Keine ${objectType} mit dem gewählten Status vorhanden.`
          )
          break
        case EventType.FILTER_COMPLETE:
          $emptyListNotifier.text(
            `Keine ${objectType} mit dem gewählten Status vorhanden.`
          )
          break
        case EventType.SEARCH_COMPLETE:
          if ($searchField.val() === "") {
            $emptyListNotifier.text(
              `Keine ${objectType} mit dem gewählten Status vorhanden.`
            )
          } else {
            $emptyListNotifier.text(
              `Keine ${objectType} für diese Suchanfrage vorhanden.`
            )
          }

          break
      }

      $emptyListNotifier.css({ display: "flex" })
    } else {
      $emptyListNotifier.hide()
    }
  },

  filter(
    $listContainer: JQuery,
    listObject: List,
    filter: FilterType,
    value: FilterValue
  ): void {
    const objectType = upperFirst($listContainer.data("objects"))
    const cookieName = `list${objectType}FilterState`
    const cookieExpiry = { expires: 365 }
    const $list = $listContainer.find(".list")
    const $listItems = $list.find("li")

    $listItems.css({ opacity: 0 })

    if (filter === FilterType.ARCHIVED) {
      switch (value) {
        case FilterValue.ACTIVE:
          listObject.filter((item) => {
            // @ts-ignore
            return item.values()[filter].trim() === "false"
          })
          break
        case FilterValue.ARCHIVED:
          listObject.filter((item) => {
            // @ts-ignore
            return item.values()[filter].trim() === "true"
          })
          break
        default:
          listObject.filter()
          value = FilterValue.ALL
      }

      Cookies.set(cookieName, value, cookieExpiry)
    }
  },

  layoutAndInit($targetListContainer: JQuery): void {
    const $targetList = $targetListContainer.find(".list")
    const $listItems = $targetList.find("li")
    const $relativeTimeFields = $targetListContainer.find(".js-relative-time")
    let lastActionMaxWidth = 0
    let statisticsMaxWidth = 0

    if ($relativeTimeFields.length) {
      relativeTime.setRelativeTimes($relativeTimeFields)
    }

    $listItems.each(function () {
      const $mainColumn = $(this).find(".column.main")
      const $infoContainer = $mainColumn.find(".info")
      const lastActionWidth = $infoContainer.find(".last-action").width()
      const statisticsWidth = $infoContainer.find(".statistics").width()

      // @ts-ignore
      lastActionMaxWidth = Math.max(lastActionMaxWidth, lastActionWidth)
      // @ts-ignore
      statisticsMaxWidth = Math.max(statisticsMaxWidth, statisticsWidth)
    })

    $listItems.each(function () {
      const $row = $(this).find(".row")
      const $mainColumn = $(this).find(".column.main")

      // We switched to static layouts for the projects list, so skip this step.
      if (!$targetListContainer.hasClass("projects")) {
        const $infoContainer = $mainColumn.find(".info")
        const $lastAction = $infoContainer.find(".last-action")
        const $statistics = $infoContainer.find(".statistics")
        const quickactionsWidth = $(this).find(".quickactions").width()
        const rowWidth = $row.width()
        let mainColumnSpaceLeft = 0

        mainColumnSpaceLeft =
          // @ts-ignore
          rowWidth - lastActionMaxWidth - statisticsMaxWidth - quickactionsWidth

        $lastAction.css({ width: lastActionMaxWidth + "px" })
        $statistics.css({
          marginRight: mainColumnSpaceLeft / 2 + "px",
          width: statisticsMaxWidth + "px",
        })
      }

      Lists.makeRowClickable($row, $mainColumn)

      $(this).animate({ opacity: 1 })
    })
  },

  makeRowClickable($row: JQuery, $mainColumn: JQuery): void {
    if ($mainColumn.find("a").is("a")) {
      $row.on("click.oskar", function (event) {
        const $mainColumn = $(this).find(".column.main")
        const $mainColumnLink = $mainColumn.find("a")

        if (
          !$(event.target).is(".btn") &&
          !$(event.target).is(".btn svg") &&
          !$(event.target).is(".btn svg use")
        ) {
          if ($mainColumnLink.length) {
            // TODO: Why is window needed?
            // @ts-ignore
            window.Turbolinks.visit($mainColumnLink.attr("href"))
          }
        }
      })
    }
  },

  search(listObject: List, string: string): void {
    listObject.fuzzySearch(string)
  },

  sort(
    listObject: List,
    value: string,
    order: string,
    alphabet = SORTING_ALPHABET
  ): void {
    listObject.sort(value, { order, alphabet })
  },
}

// TODO: Remove if we can remove this from .js.erb files
// @ts-ignore
window.Lists = Lists

export default Lists
