import dayjs from "dayjs"
import "dayjs/locale/de-at"
import relativeTimePlugin from "dayjs/plugin/relativeTime"

dayjs.locale("de-at")
dayjs.extend(relativeTimePlugin)

const RelativeTime = {
  setRelativeTimes($targetRelativeTimeFields: JQuery): void {
    $targetRelativeTimeFields.each(function () {
      const $prefix = $(this).siblings(".prefix")
      const lastActionDate = dayjs($(this).attr("datetime"))
      const hoursSinceLastAction = dayjs().diff(lastActionDate, "hour")

      if (hoursSinceLastAction < 48) {
        if ($prefix.length) {
          $prefix.text("Letzte Aktion")
        }
        $(this).text(lastActionDate.fromNow())
      } else {
        if ($prefix.length) {
          $prefix.text("Letzte Aktion am")
        }
        $(this).text(lastActionDate.format("DD. MMMM YYYY"))
      }
    })
  },
}

// TODO: Remove if we can remove this from .js.erb files
// @ts-ignore
window.RelativeTime = RelativeTime

export default RelativeTime
