import isEmpty from "lodash-es/isEmpty"
import Forms from "./forms"
import Tables from "./tables"

function notifyParents($targetCheckbox: JQuery) {
  const $selectableTable = $targetCheckbox.parents(
      ".table-inner-container.selectable"
    ),
    $targetForm = $targetCheckbox.parents("form")

  if ($targetCheckbox.attr("data-clicked") === "true") {
    if (!isEmpty($selectableTable)) {
      Tables.selectedElementsHaveChanged($targetCheckbox)
    }

    Forms.changesOccurred($targetForm)
  }
}

function toggle($checkboxes: JQuery) {
  $checkboxes.each(function () {
    if ($(this).hasClass("checked")) {
      Checkboxes.setUnchecked($(this))
    } else {
      Checkboxes.setChecked($(this))
    }
  })
}

const Checkboxes = {
  setChecked($checkboxes: JQuery): void {
    $checkboxes.each(function () {
      const $hiddenCheckbox = $(this).find('input[type="checkbox"]')
      const $hiddenField = $(this).find('input[type="hidden"]')

      if ($(this).attr("data-changing") === "true") {
        return false
      }

      $(this)
        .attr("data-changing", "true")
        .removeClass("partial")
        .addClass("checked")

      $hiddenCheckbox.prop("checked", true)
      $hiddenField.val("true")

      notifyParents($(this))

      $(this).attr("data-changing", "false")
    })
  },

  setPartial($checkboxes: JQuery): void {
    $checkboxes.each(function () {
      const $hiddenCheckbox = $(this).find('input[type="checkbox"]')
      const $hiddenField = $(this).find('input[type="hidden"]')

      if ($(this).attr("data-changing") === "true") {
        return false
      }

      $(this)
        .attr("data-changing", "true")
        .removeClass("checked")
        .addClass("partial")

      $hiddenCheckbox.prop("checked", false)
      $hiddenField.val("false")

      notifyParents($(this))

      $(this).attr("data-changing", "false")
    })
  },

  setUnchecked($checkboxes: JQuery): void {
    $checkboxes.each(function () {
      const $hiddenCheckbox = $(this).find('input[type="checkbox"]')
      const $hiddenField = $(this).find('input[type="hidden"]')

      if ($(this).attr("data-changing") === "true") {
        return false
      }

      $(this)
        .attr("data-changing", "true")
        .removeClass("partial")
        .removeClass("checked")

      $hiddenCheckbox.prop("checked", false)
      $hiddenField.val("false")

      notifyParents($(this))

      $(this).attr("data-changing", "false")
    })
  },

  build($targetCheckboxes: JQuery): void {
    $targetCheckboxes.each(function () {
      const $checkbox = $(this)
      const $btn = $checkbox.find(".btn")
      const $description = $checkbox.find(".description")
      const $hiddenCheckbox = $checkbox.find('input[type="checkbox"]')
      const $hiddenField = $checkbox.find('input[type="hidden"]')
      const $panelBodyItem = $checkbox.parents(".panel-body li")

      const triggerToggle = function ($targetCheckbox: JQuery) {
        if (
          $targetCheckbox.hasClass("disabled") ||
          $targetCheckbox.hasClass("locked")
        ) {
          return false
        }

        $targetCheckbox.attr("data-clicked", "true")
        toggle($targetCheckbox)
        $targetCheckbox.attr("data-clicked", "false")
      }

      if ($hiddenCheckbox.is(":checked")) {
        Checkboxes.setChecked($(this))
      }

      $hiddenCheckbox
        .on("focusin.oskar", () => {
          $checkbox.addClass("focused")
        })
        .on("focusout.oskar", () => {
          $checkbox.removeClass("focused")
        })

      if ($hiddenField.length) {
        if ($hiddenField.val() === "true") {
          Checkboxes.setChecked($(this))
        } else {
          Checkboxes.setUnchecked($(this))
        }
      }

      $(this).attr("data-clicked", "false")

      if (
        $(this).hasClass("checkbox-btn-with-text") ||
        $(this).hasClass("checkbox-text")
      ) {
        $btn.off("click.oskar")
        $description.off("click.oskar")
        $panelBodyItem.off("click.oskar")

        if ($panelBodyItem.length) {
          $panelBodyItem.on("click.oskar", () => {
            triggerToggle($checkbox)
          })
        } else {
          $btn.on("click.oskar", () => {
            triggerToggle($checkbox)
          })

          if ($description.length) {
            $description.on("click.oskar", () => {
              triggerToggle($checkbox)
            })
          }
        }
      } else {
        $(this).off("click.oskar")

        if ($panelBodyItem.length) {
          $panelBodyItem.on("click.oskar", () => {
            triggerToggle($checkbox)
          })
        } else {
          $(this).on("click.oskar", (e) => {
            /*
              A click on the label triggers a click on the input. Since we use
              hidden inputs for checkboxes, we filter out events on the input,
              so we don't trigger this twice. Triggering it twice and calling
              toggle() twice results in no change for the checkbox, since we'd
              go from unchecked->checked->unchecked.
            */
            if (e.target.tagName.toUpperCase() === "INPUT") {
              return
            }
            triggerToggle($checkbox)
          })
        }
      }
    })
  },

  init(): void {
    const $checkboxes = $(
      ".checkbox, .checkbox-btn, .checkbox-btn-with-text, .checkbox-text"
    )

    Checkboxes.build($checkboxes)
  },
}

export default Checkboxes
