import Checkboxes from "./checkboxes"
import { SORTING_ALPHABET } from "./lists"
import sprite from "../../images/sprite.svg"
import "tablesorter"
import { SortDefinition } from "tablesorter"
import naturalCompare from "string-natural-compare"

const Tables = {
  init(): void {
    const $tableContainer = $(".table-inner-container")
    const $sortableTable = $tableContainer.find("table.tablesorter")
    let sortList: SortDefinition[] = [[0, 0]]

    if ($tableContainer.length) {
      if ($tableContainer.hasClass("selectable")) {
        sortList = [[1, 0]]
      }

      if ($sortableTable.length) {
        $sortableTable.tablesorter({
          dateFormat: "ddmmyyyy",
          onRenderTemplate(index, content) {
            return (
              content +
              `<svg role="img" class="svg__carret_down"><use class="carret_down" xlink:href="${sprite}#carret_down"/></svg>` +
              `<svg role="img" class="svg__carret_up"><use class="carret_up" xlink:href="${sprite}#carret_up"/></svg>`
            )
          },
          sortList,
          sortRestart: true,
          textSorter: (a, b, _direction, _column, _table) => {
            return naturalCompare(a, b, { alphabet: SORTING_ALPHABET })
          },
        })

        $sortableTable.trigger("update")
      }

      if ($tableContainer.hasClass("selectable")) {
        Checkboxes.init()
      }
    }
  },

  selectedElementsHaveChanged($targetCheckbox: JQuery): void {
    const $tableContainer = $targetCheckbox.parents(
      ".table-inner-container.selectable"
    )

    if ($tableContainer) {
      const $tableBody = $tableContainer.find(".tablesorter tbody")
      const $tableHead = $tableContainer.find(".tablesorter thead")
      const $bodyCheckboxes = $tableBody.find(".checkbox")
      const $headCheckbox = $tableHead.find(".checkbox")
      const $bodyCheckboxNumber = $tableBody.find(".checkbox").length
      const $checkedBodyCheckboxNumber =
        $tableBody.find(".checkbox.checked").length

      if ($targetCheckbox.is($headCheckbox)) {
        if ($targetCheckbox.attr("data-clicked") === "false") {
          return
        }

        if ($targetCheckbox.hasClass("checked")) {
          Checkboxes.setChecked($bodyCheckboxes)
        } else {
          Checkboxes.setUnchecked($bodyCheckboxes)
        }
      } else {
        if ($checkedBodyCheckboxNumber === $bodyCheckboxNumber) {
          Checkboxes.setChecked($headCheckbox)
        } else if ($checkedBodyCheckboxNumber === 0) {
          Checkboxes.setUnchecked($headCheckbox)
        } else {
          Checkboxes.setPartial($headCheckbox)
        }
      }
    }
  },
}

export default Tables
